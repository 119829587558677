import { ActionContext } from 'vuex';
import { deleteNotification, getNotifications, updateNotification } from '@/api/notifications';
import { IRocketRoom } from '@/models/chat';
import { INotification, INotificationsParams } from '@/models/notifications';
import { ISession } from '@/models/session';
import { IState } from '..';

export interface INotificationsState {
  channels: object[];
  messages: IRocketRoom[];
  sessions: ISession[];
  notifications: INotification[];
  currentChannel: string;
  temp_channels: any[];
  isSessionChannel: boolean;
  // session
  currentSessionChannel: string;
  sessionMessages: any[],
  sessionChannels: any[],
  sessionTempChannels: any[]
}

const state: INotificationsState = {
  channels: [],
  messages: [],
  sessions: [],
  notifications: [],
  currentChannel: "",
  temp_channels: [],
  isSessionChannel: false,

  // session
  currentSessionChannel: "",
  sessionMessages: [],
  sessionChannels: [],
  sessionTempChannels: []
};

const getters = {
  notificationsNr: (state: INotificationsState) =>
    state.notifications.filter((notification) => notification.status === 'sent').length,
  notifications: (state: INotificationsState) =>
    state.notifications.filter((notification) => notification.status === 'sent'),
  messages: (state: INotificationsState) => state.messages,
  channels: (state: INotificationsState) => [...state.channels, ...state.temp_channels],
  temp_channels: (state: INotificationsState) => state.temp_channels.length ? state.temp_channels : [],
  currentChannel: (state: INotificationsState) => state.currentChannel ? state.currentChannel : null,
  isSessionChannel: (state: INotificationsState) => state.isSessionChannel,

  // session
  currentSessionChannel: (state: INotificationsState) => state.currentSessionChannel ? state.currentSessionChannel : null,
  sessionMessages: (state: INotificationsState) => state.sessionMessages,
  sessionChannels: (state: INotificationsState) => [...state.sessionChannels, ...state.sessionTempChannels],
  sessionTempChannels: (state: INotificationsState) => state.sessionTempChannels.length ? state.sessionTempChannels : [],
};

const mutations = {
  // session
  setSessionMessagesNotifications: (state: INotificationsState, messages: any) => {
    state.sessionMessages = messages;
  },
  setSessionMessagesChannels: (state: INotificationsState, channels: any) => {
    state.sessionChannels = channels;
  },
  addSessionMessagesChannel: (state: INotificationsState, channel: any) => {
    state.sessionChannels = [...state.sessionChannels, channel];
    // @ts-ignore
    // state.messages[channel.roomId] = [];
  },
  setSessionCurrentChannel: (state: INotificationsState, channel_id: string) => {
    state.currentSessionChannel = channel_id;
  },
  addSessionTempChannel: (state: INotificationsState, channel: any) => {
    state.sessionTempChannels = [...state.sessionTempChannels, channel];
    if (channel.roomId) {
      // @ts-ignore
      state.sessionMessages[channel.roomId] = [...[]];
    }
  },

  // General
  setMessagesNotifications: (state: INotificationsState, messages: any) => {
    state.messages = messages;
  },
  setMessagesChannels: (state: INotificationsState, channels: any) => {
    state.channels = channels;
  },
  addMessagesChannel: (state: INotificationsState, channel: any) => {
    state.channels = [...state.channels, channel];
    // @ts-ignore
    // state.messages[channel.roomId] = [];
  },
  setCurrentChannel: (state: INotificationsState, channel_id: string) => {
    state.currentChannel = channel_id;
  },
  addTempChannel: (state: INotificationsState, channel: any) => {
    state.temp_channels = [...state.temp_channels, channel];
    if (channel.roomId) {
      // @ts-ignore
      state.messages[channel.roomId] = [...[]];
    }
  },
  setIsSessionChannel: (state: INotificationsState, value: boolean) => {
    state.isSessionChannel = value;
  },
  setNotifications: (state: INotificationsState, notifications: INotification[]) => {
    state.notifications = notifications;
  },
  updateNotification: (state: INotificationsState, editedNotification: INotification) => {
    state.notifications = state.notifications.map((notification) =>
      editedNotification.id === notification.id
        ? {
          ...editedNotification,
          loading: false,
          error: false,
        }
        : notification,
    );
  },
  deleteNotification: (state: INotificationsState, deletedNotification: INotification) => {
    state.notifications = state.notifications.filter(
      (notification) => deletedNotification.id !== notification.id,
    );
  },
};

const actions = {
  fetchNotifications(
    context: ActionContext<INotificationsState, IState>,
    params: INotificationsParams,
  ) {
    // context.commit('setNnotificationsLoading');
    getNotifications(params)
      .then((response) => {
        context.commit('setNotifications', response.data.results);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  updateNotification(
    context: ActionContext<INotificationsState, IState>,
    notification: INotification,
  ) {
    // context.commit('setNnotificationsLoading');
    updateNotification(notification)
      .then((response) => {
        context.commit('updateNotification', response.data);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  deleteNotification(
    context: ActionContext<INotificationsState, IState>,
    notification: INotification,
  ) {
    // context.commit('setNnotificationsLoading');
    deleteNotification(notification)
      .then(() => {
        context.commit('deleteNotification', notification);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
