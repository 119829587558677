<template>
    <div v-html="content"></div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        content: {
            type: String,
            required: true,
        },
    },
})
</script>